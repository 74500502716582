@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-table/src/bootstrap-table";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700,800|Roboto:100,300,400,500,700,900&display=swap");

@font-face {
  font-family: 'Karu Lightweight';
  src: url("../fonts/Karu-ExtraLight.ttf");
}

//container
$container-width: 70%;
$break-small: 720px;
$break-large: 1200px;

//fonts
$open-sans: "Open Sans", sans-serif;
$header-font: "Roboto", sans-serif;
$title-font: "Karu Lightweight", sans-serif;

//colors
$nav-primary-color: #fafafa;
$irene-orange: #e37636;
$body-background: #E0E0E0;
$content-body-background: #EEEEEE;
$panel-body: #F5F5F5;
$text-color-black: #212121;
